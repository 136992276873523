import React from 'react'
import { Helmet } from 'react-helmet'
import '../../styles/award.less'
import Logo from '../../images/logo.svg'

import main from './img/main.jpg'
import win1 from './img/vedrov.jpg'
import win2 from './img/georg.jpg'
import win3 from './img/semenova.jpg'
import win4 from './img/bars.jpg'
import win5 from './img/kov.jpg'

const containerStyles = {
  maxWidth: 880,
  padding: '0 20px',
  margin: '0 auto',
}

const awardStyles = {
  maxWidth: 700,
  display: 'inline-block',
  textAlign: 'left',
  padding: '25px 0',
}

const DESCRIPTION =
  'Закрытая церемония награждения в рамках премии «Лучший ипотечный эксперт России». Организатор премии Ипотека.Центр.'

const MortgageExpertsAward = () => {
  return (
    <>
      <Helmet>
        <title>Премия "Лучший ипотечный эксперт России" от Ипотека.Центр</title>

        <meta name="description" content={DESCRIPTION} />
        <meta property="og:description" content={DESCRIPTION} />
      </Helmet>

      <div style={containerStyles} className="award">
        <div className="award--header">
          <a href="/" target="_blank">
            <img src={Logo} alt={'logo'} loading="lazy" />
          </a>
        </div>
        <div style={awardStyles} className="award--content">
          <h1>
            В Москве прошла церемония награждения лучших ипотечных экспертов
            России
          </h1>
          <h3>
            В Москве состоялась закрытая церемония награждения лучших менеджеров
            ипотеки в рамках премии — «Лучший ипотечный эксперт России». Премия
            учреждена в 2019 году компанией Ипотека.Центр. Всего в конкурсе
            приняли участие более 800 специалистов со всей страны. Победителями
            стали 5 лучших экспертов по условиям конкурса.
          </h3>
          <p>
            6 февраля 2020 года, в Москве прошла первая премия для специалистов
            в области ипотеки — «Лучший ипотечный эксперт России». Премия
            призвана выявить самых профессиональных экспертов рынка ипотеки
            и недвижимости со всей страны.
          </p>
          <p>
            Участниками конкурса могли стать специалисты из любого города России
            с опытом в ипотеке не менее года, зарегистрированные на онлайн
            платформе Ипотека.Центр.
          </p>
        </div>
        <div className="mainPic">
          <img src={main} alt="award" loading="lazy" />
        </div>
        <div style={awardStyles} className="award--content">
          <p>
            Платформа представляет собой бесплатный инновационный сервис, где
            ипотечный эксперт из любого города может онлайн проводить полный
            цикл сделки по ипотечному кредиту для своих клиентов: от подачи
            заявки до интеграции со страховыми и оценочными компаниями
            и регистрацией сделок в Росреестре. Все рутинные процессы
            максимально оптимизированы, включая возможность формирования единой
            анкеты для всех банков. А пользователи получают до 1 % от суммы
            выданного кредита в качестве бонуса.
          </p>
          <p>
            Победителями стали пять участников, заключивших наибольшее
            количество сделок через ипотечную платформу в ноябре и декабре 2019
            года. При равном количестве сделок победитель определялся по
            критерию наибольшей суммы.
          </p>
        </div>

        <h2>Список победителей</h2>
        <ul className="award--winners">
          <li>
            <img src={win1} alt="win1" loading="lazy" />
            <p>
              <b>Кирилл Ведров</b>1 место, <em>Санкт-Петербург</em>
            </p>
          </li>
          <li>
            <img src={win2} alt="win2" loading="lazy" />
            <p>
              <b>Анна Георгиевская</b>2 место, <em>Санкт-Петербург</em>
            </p>
          </li>
          <li>
            <img src={win3} alt="win3" loading="lazy" />
            <p>
              <b>Дина Семенова</b>3 место, Москва
            </p>
          </li>
          <li>
            <img src={win4} alt="win4" loading="lazy" />
            <p>
              <b>Рустем Барсов</b>4 место, Москва
            </p>
          </li>
          <li>
            <img src={win5} alt="win5" loading="lazy" />
            <p>
              <b>Татьяна Ковельская</b>5 место, Екатеринбург
            </p>
          </li>
        </ul>

        <div style={awardStyles} className="award--content">
          <p>
            «Мы создали цифровое рабочее пространство для экспертов ипотеки, —
            для тех, кто помогает людям в решении жилищных вопросов. В нашей
            команде работают ведущие ипотечные специалисты страны, поэтому мы
            хорошо знаем, что нужно, чтобы помочь им успешно работать
            с клиентами, — скорость, интерактивность и удобство сервиса, который
            позволяет получать самую актуальную информацию по условиям
            и возможностям разных банков в условиях изменчивого многообразия
            предложений. Конкурс помог нам понять, как сделать сервис еще лучше,
            а также наградить тех, кто показал действительно выдающиеся
            результаты», — комментирует руководитель ипотечного направления ЦФТ
            «Базис» и генеральный директор компании Ипотека.Центр Ольга
            Бажутина.
          </p>
          <h3>
            <b>
              Всего за 2019 год через платформу было выдано кредитов на сумму
              более 6 млрд рублей. Из них в декабре — 1,5 млрд рублей. А средний
              доход пользователя платформы по России составляет 150 000 рублей.
            </b>
          </h3>
          <div className="award--footer">
            <p>
              <b>Справка</b>
            </p>
            <p>
              Ипотека.Центр — ипотечная IT компания, находящаяся на стыке
              облачных технологий и реального бизнеса. Компания основана в июле
              2018 года. Является ТМ Центра финансовых технологий «БАЗИС» (ЦФТ
              БАЗИС).{' '}
              <a href="/" target="_blank">
                https://ipoteka.center
              </a>
            </p>
            <p>
              Платформа Ипотека. Центр — собственная российская разработка ЦФТ
              «БАЗИС»
            </p>
            <p>
              Инновационный сервис для профессионалов ипотечного бизнеса,
              позволяет проводить полный цикл сделки по ипотечному кредиту
              онлайн и получать до 1 % от суммы выданного кредита в качестве
              бонуса. Платформа была запущена в июне 2019 года.
            </p>
            <p>
              Контакты для прессы:{' '}
              <a href="mailto:birfas@basis.center">birfas@basis.center</a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default MortgageExpertsAward
